import { Box, chakra, Container, Divider, Text } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { useHookForm } from '@/hooks/useHookForm';

// import { InternationalSponsor } from '@/components/Register/InternationalSponsor';
import { Button } from '@/components/UI/Buttons/Button';

import { RegistrationFormError } from '@/models/api';
import { RegisterForm } from '@/models/forms/RegisterForm';
import { AppService, RegistrationService } from '@/services';
import { StringUtil } from '@/utils/StringUtil';

import {
    IntroductoryPerson,
    Password,
    PersonalData,
    RegisterAgreements,
    RegisterFailed
} from './index';
import { IntroHeader } from './IntroHeader/IntroHeader';
import { RegisterUserFormProps } from './RegisterUserFormProps';

export const RegisterUserForm = (props: RegisterUserFormProps) => {
    const registerForm = useHookForm<RegisterForm>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submittingErrors, setSubmittingErrors] = useState<RegistrationFormError[]>([]);
    const [internationalSponsorChecked, setInternationalSponsorChecked] = useState(false);
    // eslint-disable-next-line unused-imports/no-unused-vars
    const [introductoryPersonChecked, setIntroductoryPersonChecked] = useState(false);
    const { handleSubmit } = registerForm;
    const registerFailedRef = useRef<HTMLFormElement>(null);
    const prepareRegistrationPayload = (data: RegisterForm) => {
        delete data.confirmPassword;
        delete data.confirmEmail;
        delete data.adult;
        for (const key in data) {
            const value = data[key as keyof RegisterForm];

            if (value === '' || value === null || value === undefined) {
                delete data[key as keyof RegisterForm];
            }
        }
        if (data.phone) {
            data.phone = AppService.getPhonePrefix().concat(' ').concat(data.phone);
            data.phone = StringUtil.removeSpaces(data.phone);
        }

        if (data.phone === '' || data.phone === null || data.phone === undefined) {
            delete data.phone;
        }

        data.countryCode = AppService.getAppCountryCode();

        if (data.sponsorNo) {
            data.sponsorNo = AppService.getPrefixedAccountNumber(data.sponsorNo);
        }
    };
    // eslint-disable-next-line unused-imports/no-unused-vars
    const internationalSponsorCallback = useCallback((data: boolean) => {
        setInternationalSponsorChecked(!!data);
    }, []);
    const introductoryPersonCallback = useCallback((data: boolean) => {
        setIntroductoryPersonChecked(!!data);
    }, []);
    const onRegisterSubmit: SubmitHandler<RegisterForm> = (data) => {
        setIsSubmitting(() => true);
        setSubmittingErrors(() => []);
        prepareRegistrationPayload(data);

        RegistrationService.register(data)
            .then((response) => {
                if (props?.onRegisterSubmit) {
                    props.onRegisterSubmit(response);
                }
            })
            .catch((error: AxiosError<{ errors: RegistrationFormError[] }>) => {
                const errors = error.response?.data?.errors || [];
                setSubmittingErrors(() => errors);
            })
            .finally(() => {
                setIsSubmitting(() => false);
            });
    };

    useEffect(() => {
        if (!submittingErrors.length) {
            return;
        }
        registerFailedRef.current?.scrollIntoView({
            behavior: 'smooth'
        });
    }, [submittingErrors]);

    return (
        <chakra.form ref={registerFailedRef} noValidate onSubmit={handleSubmit(onRegisterSubmit)}>
            <Container as="fieldset" disabled={isSubmitting} maxW="8xl" marginBottom={9}>
                <Text textAlign="center" fontWeight="bold" marginBottom="2">
                    <FormattedMessage id="page.register.intro-text" />
                </Text>
                <IntroHeader />
            </Container>
            <Container maxW="5xl" padding={6} borderWidth={1} borderColor="grey.border">
                {!!submittingErrors.length && <RegisterFailed errors={submittingErrors} />}
                <PersonalData formInstance={registerForm} errorsField={submittingErrors} />
                <Divider orientation="horizontal" my="1.5rem" />
                <IntroductoryPerson
                    formInstance={registerForm}
                    internationalSponsor={internationalSponsorChecked}
                    introductoryPersonCallback={introductoryPersonCallback}
                />
                <Divider orientation="horizontal" my="1.5rem" />
                <Password formInstance={registerForm} />
                <Divider orientation="horizontal" my="1.5rem" />
                <RegisterAgreements formInstance={registerForm} />
                <Box textAlign="center">
                    <Button type="submit" isLoading={isSubmitting}>
                        <FormattedMessage id="register" />
                    </Button>
                    <Text fontSize="xs" marginTop={4}>
                        <FormattedMessage id="page.register.required-fields-info" />
                    </Text>
                </Box>
            </Container>
        </chakra.form>
    );
};

export default RegisterUserForm;
